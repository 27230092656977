import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default ({ fill, bgColor }) => {

  const useStyles = makeStyles(() => ({
    root: {
      fill: fill || 'rgb(248, 249, 251)',
      transition: 'fill 400ms ease-in-out 0s',
      zIndex: '1000',
      //marginTop: '10px',
      position: 'relative',
      display: 'block',
      //bottom: '-1px',
      width: '100%',
      backgroundColor: bgColor || 'transparent',
      pointerEvents: 'none',
      userSelect: 'none',
      verticalAlign: 'middle',
      overflow: 'hidden',
    }
  }));

  const classes = useStyles();

  return (
    <svg className={classes.root} viewBox="0 0 1440 120" xmlns="http://www.w3.org/2000/svg">
      <path d="M1440 21.21V120H0V21.21C120 35.07 240 42 360 42s240-6.93 360-20.79c88.328-8.794 154.574-14.333 198.738-16.618A3120.562 3120.562 0 0 1 1080 .42c120 0 240 6.93 360 20.79z"></path>
    </svg>
  )
}