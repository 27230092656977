import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RegisterButton from '../register';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    
    [theme.breakpoints.up('sm')]: {
      //minHeight: '200px',
      alignContent: 'center',
    },
    [theme.breakpoints.down('md')]: {
      //minHeight: '400px',
      alignContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '400px',
      top: '56px',
    },
    backgroundColor: '#2E3440',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: '#ECEFF4',
    position: 'relative',
    top: '64px',
    flexWrap: 'wrap',
    alignContent: 'baseline',
    paddingBottom: '20px',
  },
  h2: {
    margin: 0,
  },
  img: {
    height: 200,
  }
}));

export default () => {
  const classes = useStyles();

  return (
    <header className={classes.root}>
      <div>
        <img className={classes.img} src={`img/cejs-logo3.svg`}  alt="logo" />
      </div>
      <div>
        <h2 className={classes.h2}>
          CEJS 2019
        </h2>
        <p>
          5 de Outubro de 2019
        </p>
        <RegisterButton
          href="https://www.sympla.com.br/cejs-2019__564280"
          size="large"
          variant="contained"
          color="primary"
        >
          INSCRIÇÃO
        </RegisterButton>
      </div>
    </header>
  )
}
