import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: '#F8F9FB',
    position: 'relative',
    marginTop: '-20px',
    paddingBottom: '20px',
  },
  h2: {
    fontSize: '1.5em',
    color: '#4C566A',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: '1.8em',
    color: '#4C566A',
  },
  gridList: {
    margin: '0 auto!important',
   [theme.breakpoints.up('sm')]: {
      width: '90%',
      alignContent: 'center',
    },
  },
  gridListTile: {
    width: '370px!important',
    height: '200px!important',
    margin: 'auto',
  },
  logo: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const data = {
  "Ouro": [
    {
      name: 'Fortes Tecnologia',
      url: 'http://www.fortestecnologia.com.br',
      logo: 'img/sponsors/fortes.png'
    },
    {
      name: 'Casa Magalhães',
      url: 'http://www.casamagalhaes.com.br',
      logo: 'img/sponsors/casamagalhaes.png'
    },
    {
      name: 'Quero Educação',
      url: 'https://www.quero.com/',
      logo: 'img/sponsors/logo-quero-education-color.png',
    },
  ],
  "Prata": [
    {
      name: 'Alura',
      url: 'http://www.alura.com.br/',
      logo: 'img/sponsors/alura.png'
    },
    {
      name: 'Casa do Código',
      url: 'http://www.casadocodigo.com.br/',
      logo: 'img/sponsors/casa_do_codigo.svg'
    },
    {
      name: 'Caelum',
      url: 'http://www.caelum.com.br',
      logo: 'img/sponsors/caelum.svg'
    },
    {
      name: 'Freshworks',
      url: 'https://www.freshworks.com/br/',
      logo: 'img/sponsors/logo-fworks-black.svg',
      style: {
        height: 300
      },
    },
  ],
  "Bronze": [
    {
      name: 'Globo.com',
      url: 'http://www.globo.com',
      logo: 'img/sponsors/globo.png'
    },
    {
      name: 'Microsoft',
      url: 'http://www.microsoft.com.br',
      logo: 'img/sponsors/microsoft.png'
    },
    {
      name: 'Centauro',
      url: 'https://www.centauro.com.br/',
      logo: 'img/sponsors/logo_centauro_400x400.jpg',
    },
  ],
  "Apoio": [
    {
      name: 'Produto Reativo',
      url: 'https://produtoreativo.com.br',
      logo: 'img/sponsors/produtoreativo.png'
    },
    {
      name: 'Uni7',
      url: 'http://www.uni7.edu.br/',
      logo: 'img/sponsors/uni7.svg',
    }
  ]
};

export default () => {
  const classes = useStyles();
  return (
    <div id="patrocinadores">
      <h2 className={classes.h2}>PATROCINADORES</h2>
      {Object.keys(data).map(key => (
        <Fragment key={`frag-${key}`}>
          <h3 className={classes.h3}>{key}</h3>
          <GridList key={key} className={classes.gridList}>
            {data[key].map(sponsor => (
              <GridListTile key={sponsor.logo} className={classes.gridListTile}>
                <a href={sponsor.url} target='_blank' rel="noopener noreferrer">
                  <img
                    src={sponsor.logo}
                    alt={sponsor.name}
                    className={classes.logo}
                    style={sponsor.style}
                  />
                </a>
              </GridListTile>
            ))}
          </GridList>
        </Fragment>
      ))} 
    </div>
  )
}
