const speakers =  [
  {
    img: `img/speakers/adrianaeray.jpg`,
    title: 'Adriana Fintenele e Ray Zaranza - SAS',
    author: 'Líder de Produtos (Adriana) e Designer de Produtos (Ray)',
    link: 'https://www.linkedin.com/in/fonteneleadriana/',
    desc: 'Como enfrentamos o temido legado para criar o nosso Design System',
    hour: '09:00 a 09:40',
  },
  {
    img: `img/speakers/thales.jpg`,
    title: 'Thales Valle - Centauro',
    author: 'Chapter Lead Front-End Engineering',
    link: 'https://www.linkedin.com/in/thales-jacob-valle-8b90825b/',
    desc: 'Case Centauro: Como a migração para SPA aumentou a conversão em até 107%',
    hour: '09:40 a 10:20',
  },
  {
    img: `img/speakers/renanbandeira.jpg`,
    title: 'Renan Bandeira - GreenMile',
    author: 'Mobile Tech Lead',
    link: 'https://about.me/renanbandeira',
    desc: 'Desenvolvendo apps com React e Cordova',
    hour: '11:00 a 11:40',
  },
  {
    img: `img/speakers/janderson.jpg`,
    title: 'Janderson Souza Matias - Agenda Edu',
    author: 'Desenvolvedor front-end',
    link: 'https://www.linkedin.com/in/janderson-souza',
    desc: 'Animando a Web',
    hour: '11:40 a 12:20',
  },
  {
    img: `img/speakers/sebastian.jpg`,
    title: 'Sebastian Ferrari - Taller',
    author: 'CTO & Co-Founder',
    link: 'https://www.linkedin.com/in/sebas5384/',
    desc: 'De um sistema legado para micro serviços com GraphQL',
    hour: '14:00 a 14:40',
  },
  {
    img: `img/speakers/sillascamara.jpg`,
    title: 'Sillas Câmara - OSF Global Services',
    author: 'Desenvolvedor Full Stack',
    link: 'https://linkedin.com/in/sillasmcamara',
    desc: 'PWA: Qual o rumo das aplicações progressivas? Já é a hora de investir?',
  },
  {
    img: `img/speakers/nonatodias.png`,
    title: 'Nonato Dias - OSF Global Services',
    author: 'Desenvolvedor Full Stack',
    link: 'https://br.linkedin.com/in/nonato-dias',
    desc: 'PWA: Qual o rumo das aplicações progressivas? Já é a hora de investir?',
    hour: '14:40 a 15:20',
  },
  {
    img: `img/speakers/yuri.jpg`,
    title: 'Iury Krieger - Linx Impulse',
    author: 'Desenvolvedor Full Stack',
    link: 'https://www.linkedin.com/in/iurykrieger',
    desc: 'Vue WebComponents: Renovando seu legado com Microfrontends',
    hour: '16:00 a 16:40',
  },
  {
    img: `img/speakers/danilo.vaz2.jpg`,
    title: 'Danilo Vaz - Quero Educação',
    author: 'Front-End Developer',
    link: 'https://www.linkedin.com/in/danilovaz/',
    desc: 'Zilla: Criação de um Design System escalável em um produto com milhões de acessos',
    hour: '16:40 a 17:20',
  },
];

const schedule = [
  speakers[0], speakers[1],
  {
    hour: '10:20 a 11:00',
    desc: 'Coffee Break patrocinado pela Quero Educação'  
  },
  speakers[2], speakers[3],
  {
    hour: '12:20 a 14:00',
    desc: 'Intervalo para o almoço'  
  },
  speakers[4], speakers[6],
  {
    hour: '15:20 a 16:00',
    desc: 'Coffee Break patrocinado pela Quero Educação'  
  },
  speakers[7], speakers[8],
]


const initialState = { schedule, speakers };

export default (state = initialState, action) => {

  return state;
}