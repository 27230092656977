import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  status: {
    danger: 'orange',
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#ffffff',
        color: '#4C566A',
      },
    },
    MuiGridListTileBar: {
      title: {
        whiteSpace: 'normal',
        lineHeight: '16px',
      }
    },
  }
});

export default function Theme(props) {
    return (
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    );
  }