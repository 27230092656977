import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Theme from './components/theme';
import Menu from './components/menu';
import Registration from './components/registration';
import Cloud from './components/cloud';
import Speakers from './components/speakers';
import Local from './components/local';
import Sponsors from './components/sponsors';
import Schedule from './components/schedule';

import reducer from './api/reducer';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  }
});

const store = createStore(
  reducer, 
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <Theme>
        <div className={classes.root}>
          <AppBar color="default" position="fixed">
            <Toolbar>
              <Menu />
            </Toolbar>
          </AppBar>
          <div className="App">
            <Registration />
            <Cloud fill="#fff" bgColor="#2E3440" />
            <Schedule />
            <Cloud />
            <Speakers />
            <Cloud fill="#fff" bgColor="#F8F9FB" />
            <Local />
            <Sponsors />
          </div>
        </div>
      </Theme>
    </Provider>
  );
}

export default App;
