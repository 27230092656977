import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: '#F8F9FB',
    position: 'relative',
    marginTop: '-20px',
    paddingBottom: '20px',
  },
  gridList: {
    //width: '90%',
    //height: 450,
   // margin: 'auto!important',
   [theme.breakpoints.up('sm')]: {
      width: '90%',
      alignContent: 'center',
    },
  },
  gridListTile: {
    width: '370px!important',
    height: '400px!important',
    margin: 'auto',
  },
  gridListTileBar: {
    whiteSpace: 'normal!important'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  h2: {
    fontSize: '1.5em',
    color: '#4C566A',
    fontWeight: 'bold',
  }
}));

const mapStateToProps = ({ speakers }) => ({ speakers });

export default connect(mapStateToProps)(({ speakers }) => {
  const classes = useStyles();
  return (
    <div id="palestrantes" className={classes.root}>
      <GridList cellHeight={200} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="h2" className={classes.h2}>PALESTRANTES</ListSubheader>
        </GridListTile>
        {speakers.map(tile => (
          <GridListTile className={classes.gridListTile} key={tile.title}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar
              
              title={tile.desc}
              subtitle={
                <div>
                  {tile.title}
                  <br />
                  {tile.author}
                </div>
              }
              actionIcon={
                <IconButton
                  href={tile.link || '#'}
                  aria-label={`info about ${tile.title}`}
                  className={classes.icon}>
                  <InfoIcon />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
});
