import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      //backgroundColor: '#ECEFF4',
      color: '#4C566A',
      fontWeight: 'bold'
    },
    menu: {
      display: 'inline-flex',
      margin: 'auto'
    },
  }));

export default () => {
    const classes = useStyles();
    return (
        <div className={classes.menu}>
          <Button
            href="https://www.sympla.com.br/cejs-2019__564280"
            className={classes.button}>
              Inscrição
          </Button>
          <Button
            href="#agenda"
            className={classes.button}>Agenda</Button>
          <Button
            href="#palestrantes"
            className={classes.button}>Palestrantes</Button>
          <Button
            href="#local"
            className={classes.button}>
              Local
          </Button>
          <Button
            href="#patrocinadores"
            className={classes.button}>
              Patrocinadores
          </Button>
          <Button
            href="http://www.cejs.com.br/2018/"
            className={classes.button}>
              2018
          </Button>
        </div>
    )
};
