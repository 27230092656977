import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ColorButton = withStyles(theme => ({
    root: {
      color: '#ECEFF4',
      backgroundColor: '#A3BE8C',
      '&:hover': {
        backgroundColor: '#8FBCBB',
      },
    },
  }))(Button);

export default ColorButton;