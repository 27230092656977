import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  h2: {
    fontSize: '1.5em',
    color: '#4C566A',
    fontWeight: 'bold',
  }
}));

export default () => {
  const classes = useStyles();
  return (
    <div id="local">
      <h2 className={classes.h2}>
        Local
      </h2>
      <div>
        <p>
          Universidade 7 de Setembro - UNI7
        </p>
        <p>
          Av. Alm. Maximiano da Fonseca, 1395 - Eng. Luciano Cavalcante - Fortaleza/CE
        </p>
      </div>
      <iframe title="Localização" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.179779157488!2d-38.485838185984996!3d-3.7710271972565486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c745fefe74c177%3A0x3d5127cdeb3dde1d!2sCentro+Universit%C3%A1rio+7+de+Setembro!5e0!3m2!1sen!2sbr!4v1563222751027!5m2!1sen!2sbr" width="100%" height="600" frameBorder="0" allowFullScreen></iframe>
    </div>
  );
}